<template>
  <div class="container">
    <div class="content">
      <div class="topbox">
        <img src="../../../assets/bannerweb.png" alt="">
      </div>
      <div class="boxitem">
        <div class="titleimg">
          <img src="../../../assets/gonggaoweb.png" alt="">
          <div class="bgborder"></div>
        </div>
        <div class="items" v-for="(item,index) in items" :key="index" @click="jumptodetail">
          <div class="flexbox">
            <div class="boxleft">
              <div class="databox">
                <div class="datatime">{{item.datatime}}</div>
                <div class="datamounted">{{item.data}}</div>
              </div>
            </div>
            <div class="boxright">
              <span class="top" v-if="item.istop==true">【置顶】</span>
              {{item.content}}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      items: [
        {
          istop: true,
          data: '2022/06',
          datatime: '13',
          content: '关于开展第五届“闪亮的日子——青春该有的模样”大学生就业创业人物事迹征集的通知'
        },
        {
          istop: true,
          data: '2022/05',
          datatime: '28',
          content: '转发《教育部关于举办“24365校园招聘服务”系列专场招聘活动的通知》'
        },
        {
          istop: false,
          data: '2022/04',
          datatime: '13',
          content: '关于开展第五届“闪亮的日子——青春该有的模样”大学生就业创业人物事迹征集的通知'
        },
        {
          istop: false,
          data: '2022/03',
          datatime: '11',
          content: '转发《教育部关于举办“24365校园招聘服务”系列专场招聘活动的通知》'
        }
      ]
    }
  },
  methods: {
    jumptodetail () {
      this.$router.push({
        path: '/newsdetailsweb'
      })
    }
  }
}
</script>

<style scoped lang="less">
.container{
  min-height: 100vh;
  background-image: url(../../../assets/bgcolor.png);
  background-repeat: no-repeat;
  background-size: 100% 50%;
  .content{
    padding: 15px;
    box-sizing: border-box;
    .topbox{
      width: 100%;
      height: 129px;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .boxitem{
      margin-top: 15px;
      border-radius: 10px;
      background-color: #fff;
      padding: 20px 15px;
      .titleimg{
        img{
          width: 82px;
          height: 17px;
        }
        .bgborder{
          width: 82px;
          height: 2px;
          background: linear-gradient(90deg, #004D9B 0%, #FFFFFF 100%);
        }
      }
      .items{
        padding: 15px 0px;
        border-bottom: 1px solid #DDDDDD;
        .flexbox{
          display: flex;
          align-items: center;
          .boxleft{
            width: 56px;
            height: 42px;
            background-image: url(../../../assets/riliweb.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;
            text-align: center;
            .databox{
              .datatime{
                font-size: 22px;
                font-weight: bold;
                color: #f00000;
              }
              .datamounted{
                color: #FFFFFF;
                font-size: 11px;
                margin-top: -3px;
              }
            }
          }
          .boxright{
            margin-left: 10px;
            width: calc(100vw - 76px);
            font-size: 14px;
            line-height: 22px;
            color: #333333;
            overflow: hidden;
            display: -webkit-box;
            white-space: normal !important;
            word-wrap: break-word;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            .top{
              color: #f00000;
              font-weight: bold;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

</style>
